import {AccessReference} from '../models/access-reference.model';
import {isUndefined} from 'util';

declare const require: any;
const moment = require('moment');
moment.locale('it');

export abstract class Utilities {
  static ACCESS_REFERENCE = 'accessReference';
  static patternOnlyNumber = /^\d+$/;
  static patternNumber = '^[0-9]*$';
  static patternPhoneNumber = '^[0-9]{6,11}$';
  static patternEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  static patternOnlyLetters = '^[a-zA-z][a-zA-Z ]*$';
  static patternAlphanumeric = '^[a-zA-Z0-9]*$';
  static patternNotInitWhiteSpace = '^[A-Za-z0-9][A-Za-z0-9 ]*$';
  static codiceFiscalePattern = '^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$';


  public static isEmptyObject(obj) {
    return (obj && (Object.keys(obj).length === 0));
  }

  public static compareArray(a1: any[], a2: any[]): boolean {
    if (Utilities.isVoid(a1) || Utilities.isVoid(a2) || a1.length !== a2.length) {
      return false;
    }
    for (let i = 0; i < a1.length; i++) {
      if (a1[i] !== a2[i]) {
        return false;
      }
    }
    return true;
  }

  public static isVoid(value: any): boolean {
    return value === null || isUndefined(value) || value === '';
  }

  public static includesArray(a1: any[], a2: any[]): boolean {
    if (Utilities.isVoid(a1) || Utilities.isVoid(a2) || a1.length !== a2.length) {
      return false;
    }
    for (let i = 0; i < a1.length; i++) {
      if (!a1.includes(a2[i])) {
        return false;
      }
      if (!a2.includes(a1[i])) {
        return false;
      }
    }
    return true;
  }

  public static pressedEnter(event: any): boolean {
    return event instanceof KeyboardEvent && (event.keyCode === 13 || event.code === 'Enter' || event.code === 'NumpadEnter');
  }

  public static getAccessReference(): AccessReference {
    return JSON.parse(localStorage.getItem(Utilities.ACCESS_REFERENCE)) as AccessReference;
  }


  public static getDate(d: any): Date {
    if (d) {
      return new Date(moment(d));
    }
    return null;
  }

  public static downloadFile(blob: any, name: string = 'file') {
    const file = new File([blob], name, blob);
    const url = window.URL.createObjectURL(file);
    // const  fileUrl: any = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    const a = document.createElement('a');
    // a.href = fileUrl.changingThisBreaksApplicationSecurity;
    a.href = url;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}


export interface MethodDatePickerInterface {
  doSameThing();

  setErrorDateValidator(obj: any);

  setErrorMinValidator(obj: any);

  setErrorMaxValidator(obj: any);

  clearValidators(obj: any);
}
