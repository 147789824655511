import {Subject} from 'rxjs/Subject';
import {Utilities} from '../utilities/utilities';

export abstract class PollingBaseService {
  protected BASE_TIMER = 300000;
  protected MAXIMUM_ATTEMPTS = -1;
  protected attempts = 0;
  protected interval: any;
  protected pollingSubject = new Subject<any>();
  public pollingObservable = this.pollingSubject.asObservable();
  public lastResult: any;

  constructor() {
  }

  startPolling(skipFirstCall?: boolean) {
    if (Utilities.isVoid(skipFirstCall) || !skipFirstCall) {
      setTimeout(() => {
        this.doAction();
      }, 0);
    }
    this.interval = setInterval(() => {
      this.doAction();
    }, this.BASE_TIMER);
  }

  protected doAction() {
  }

  stopPolling() {
    if (!Utilities.isVoid(this.interval)) {
      clearInterval(this.interval);
    }
  }
}
