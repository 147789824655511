<mat-expansion-panel class="amd-expansion-panel amd-expansion-panel--search" #ordersForm [expanded]="isExpanded" (opened)="customOnExpandEventEmitter.emit(true)" (closed)="customOnExpandEventEmitter.emit(false)">
  <mat-expansion-panel-header expandedHeight="80px" collapsedHeight="80px">
    <mat-panel-description *ngIf="haveActivatedFilters" class="amd-expansion-panel__description amd-expansion-panel__description--border amd-expansion-panel__description--align-end">
      <a class="amd-link" (click)="customOnResetFilterEventEmitter.emit($event)">
        <i *ngIf="mainIconClass" [ngClass]="mainIconClass" aria-hidden="true"></i>
        <i *ngIf="!mainIconClass" class="amd-icon--font amd-icon--close-small-16x16" aria-hidden="true"></i>
        <ng-container *ngIf="clearFilterButtonText">{{clearFilterButtonText}}</ng-container>
        <ng-container *ngIf="!clearFilterButtonText" >Azzera filtri</ng-container>
      </a>
    </mat-panel-description>
    <mat-panel-title>
            <span class="amd-expansion-panel__title" id="step_list_one">
                <i class="amd-icon--font amd-icon--filtra-ricerca-24x24"></i>
                <ng-container *ngIf="title">{{title}}</ng-container>
                <ng-container *ngIf="!title">Filtra la ricerca</ng-container>
            </span>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="amd-expansion-panel__body">
    <ng-content></ng-content>
  </div>
  <mat-action-row>
    <button type="submit" [attr.form]="formId" mat-button mat-raised-button class="amd-button amd-button--raised myald-lowercase" [disabled]="formInvalid" (click)="customOnSubmitEventEmitter.emit($event)">
      <ng-container *ngIf="searchButtonText">{{searchButtonText}}</ng-container>
      <ng-container *ngIf="!searchButtonText" >Effettua Ricerca</ng-container>
    </button>
  </mat-action-row>
</mat-expansion-panel>
