import {Injectable} from '@angular/core';
import {
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpInterceptor
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UserStoreService} from '../core/services/user-store.service';
import {LoginService} from '../core/services/login.service';
import {Utilities} from '../shared/utilities/utilities';

@Injectable()
export class UserExistInterceptor implements HttpInterceptor {
  constructor(
    private userStore: UserStoreService,
    private loginService: LoginService,
  ) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = Utilities.getAccessReference();
    const user = this.userStore.getUSer();
    if (token && !user) {
      this.loginService.getUserByToken(token.accessToken);
    }
    const modified = req.clone();
    return next.handle(modified);
  }
}
