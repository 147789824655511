import {Observable} from 'rxjs';


export class AttachedFile {
  uuid: string;


  static createFileContent(mFile: File): Observable<any> {
    return new Observable(
      observer => {
        const reader: FileReader = new FileReader();

        reader.onload = (event) => {
          observer.next(event.target.result);
          observer.complete();
        };

        reader.onerror = (error) => {
          console.error('Error: ' + error);
          observer.next(null);
          observer.complete();
        };

        reader.readAsDataURL(mFile);
      });
  }

  static resizeImage(base64Str, maxWidth = 400, maxHeight = 400): Observable<any> {
    return new Observable(
      observer => {
        const img = new Image();
        img.src = base64Str;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const MAX_WIDTH = maxWidth;
          const MAX_HEIGHT = maxHeight;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);
          observer.next(canvas.toDataURL());
          observer.complete();
        };
      });
  }

  static escapeBase64(fileContent: string): string {
    if (fileContent) {
      return fileContent.substring(fileContent.indexOf(',') + 1);
    }
    return null;
  }

  static createMultiPartFile(mFile: File): Observable<any> {
    return new Observable(
      observer => {
        const file: FormData = new FormData();
        file.append('file', mFile);
        observer.next(file);
      });
  }

}



