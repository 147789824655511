<div class="container-menu">

  <mat-nav-list>
    <ng-container *ngFor="let men of menu">
      <a routerLinkActive="active" routerLink="{{role}}/{{men.page}}" mat-list-item >
        <mat-icon class="item-menu">{{men.icon}}</mat-icon> <span class="item-menu-label">{{men.label}}</span>
      </a>
    </ng-container>
  </mat-nav-list>

</div>
