<h1 mat-dialog-title> Richiedi un nuova password </h1>
<div mat-dialog-content>

  <form [formGroup]="forgotPasswordForm">
    <p>
      <mat-form-field appearance="fill">
        <mat-label>Enter your email</mat-label>
        <input matInput  formControlName="email">
        <mat-error *ngIf="forgotPasswordForm.controls['email'].hasError('email')">
          <ng-container>Formatto email sbagliato</ng-container>
        </mat-error>
        <mat-error *ngIf="forgotPasswordForm.controls['email'].hasError('required')">
          <ng-container>email obbligatoria</ng-container>
        </mat-error>
      </mat-form-field>
    </p>

  </form>


</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">No </button>
  <button mat-button color="primary" [disabled]="!forgotPasswordForm.valid" (click)="newPassword()" cdkFocusInitial>Richiede nuova password</button>
</div>
