import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {User, UserInfo} from '../model/user';

@Injectable({
  providedIn: 'root'
})
export class AlertStoreService {
  alert: any[] = [];

  private alertSubject = new Subject<any[]>();
  private alertObservable$ = this.alertSubject.asObservable();

  constructor() {
  }

  init(alerts: any[]) {
    this.alert = [];
    this.alert = alerts;
    this.alertSubject.next(this.alert);
  }

  getAlertObservable(): Observable<any []> {
    return this.alertObservable$;
  }

  isRead(alert: any) {
    this.alert = this.alert.filter(element => element != alert);
    this.alertSubject.next(this.alert);
  }
}
