import {Injectable, Injector} from '@angular/core';
import {
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AlertOptionsModel} from '../core/model/alert.options.model';
import {NotificationService} from '../core/services/notification.service';
import {ErrorService} from '../services/error.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private alertService: NotificationService
  ) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status !== 401) {
          const errorService = this.injector.get(ErrorService);
          const messageCustom = error.error?.message ? error.error.message : '';
          const errmess = errorService.getServerErrorMessage(error);
          const messge = messageCustom + ' : ' + (errmess && errmess != ''  ?  errmess : 'Error!' );
          console.log(error);
          this.alertService.error(messge, AlertOptionsModel.getOptions(true, false));
        }
        return throwError(error);
      })

    );
  }
}
