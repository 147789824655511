import {Injectable} from '@angular/core';
import {User, UserInfo} from '../model/user';
import {Observable, Subject} from 'rxjs';
import {AccessReference} from '../../shared/models/access-reference.model';
import {Utilities} from '../../shared/utilities/utilities';

@Injectable({
  providedIn: 'root'
})
export class UserStoreService {
  user: any;
  companySelected: any;
  allCompanies: any[];

  private userSubject = new Subject<UserInfo>();
  private companySubject = new Subject<any>();
  private userObservable$ = this.userSubject.asObservable();
  private companyObservable$ = this.companySubject.asObservable();

  constructor() {
  }

  protected static setAccessReference(token: string) {
    UserStoreService.deleteAccessReference();
    const accessReference: AccessReference = new AccessReference();
    accessReference.accessToken = token;
    if (accessReference && accessReference.accessToken) {
      localStorage.setItem(Utilities.ACCESS_REFERENCE, JSON.stringify(accessReference));
    }
  }

  protected static deleteAccessReference() {
    localStorage.removeItem(Utilities.ACCESS_REFERENCE);
  }

  setAllCompanies(all) {
    this.allCompanies = all;
  }

  getAllCompanies() {
    return this.allCompanies;
  }


  setCompany(element) {
    if (this.companySelected !== element) {
      this.companySelected = null;
      this.companySelected = element;
      this.companySubject.next(this.companySelected);
    }
  }

  getCompany(): any {
    return this.companySelected;
  }

  init(user: User) {
    if (this.user !== user) {
      this.user = null;
      this.user = user;
      UserStoreService.setAccessReference(this.user.token);
      this.userSubject.next(this.user?.userInfo);
    }
  }

  refreshAccessReference(newToken: string) {
    UserStoreService.setAccessReference(newToken);
  }

  getUserObservable(): Observable<UserInfo> {
    return this.userObservable$;
  }

  getCompanyObservable(): Observable<any> {
    return this.companyObservable$;
  }

  getUSer() {
    return this.user;
  }

  delete() {
    UserStoreService.deleteAccessReference();
    this.user = null;
    this.companySelected = null;
    this.userSubject.next(this.user);
  }

}

