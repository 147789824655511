<br>
<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
  <ng-container *ngFor="let label of displayedColumns">
    {{label}}
    <ng-container matColumnDef="{{label}}">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{label}} </th>
      <td mat-cell (click)="elementSelectedEventEmitter.emit(element)" *matCellDef="let element"> {{element[label]}} </td>
    </ng-container>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
