<div class="row mb-3 mt-2" *ngIf="elements && elements.length > 0">
  <div class="col-12">
    <ng-container *ngIf="isFilterable && !isModal; else isFilterable ? search : null" >
      <mat-expansion-panel *ngIf="isFilterable && !isModal">
        <mat-expansion-panel-header expandedHeight="80px" collapsedHeight="80px">
          <mat-panel-title>
            <ng-container>Filtra la ricerca</ng-container>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template *ngTemplateOutlet="search"> </ng-template>
      </mat-expansion-panel>
    </ng-container>
  </div>
</div>

<ng-template #search >
  <mat-form-field *ngIf="isFilterable">
    <mat-label>Filtro</mat-label>
    <input matInput (keyup)="applyFilter($event)" #input>
  </mat-form-field>
</ng-template>

<div class="row mt-2">
  <div class="col-12">
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort matSortActive="{{labelSort}}" matSortDirection="{{sortV}}" class="mat-elevation-z8">
        <ng-container *ngFor="let label of displayedColumns">
          <ng-container matColumnDef="{{label}}">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{model[label]}} </th>
            <td mat-cell *matCellDef="let element"  (click)="onClickCell(element)">
              <ng-container [ngTemplateOutlet]="!isButton(label) ? setlabel : buttonmanager"
                            [ngTemplateOutletContext]="{element:element, label: label }">
              </ng-container>
            </td>
          </ng-container>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <div *ngIf="!elements || elements.length === 0">
        <div class="row">
          <div class="col-12 p-4 text-center">
            Nessun dato trovato
          </div>
        </div>
      </div>

      <mat-paginator  [pageSizeOptions]="[5, 10, 20, 50]" showFirstLastButtons></mat-paginator>
    </div>
  </div>
</div>

<ng-template #setlabel let-element='element' let-label='label'>
  <ng-container *ngIf="!isValidDate(element[label]); else customDate" > {{element[label]}} </ng-container>
  <ng-template #customDate> {{ getDate(element[label])  | Paride : [] }} </ng-template>
</ng-template>

<ng-template #buttonmanager let-element='element' let-label='label'>
  <ng-container *ngIf="label == 'aggiungi'">
    <mat-checkbox (change)="onchangeCheckbox($event, element)"></mat-checkbox>
  </ng-container>

  <ng-container *ngIf="label !== 'aggiungi'">
    <button mat-raised-button color="{{buttonsTableColor[label]}}" (click)="buttonClick(element, label)">{{bottonsModel[label]}}</button>
  </ng-container>
</ng-template>



