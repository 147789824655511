import {Injectable} from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import {throwError, Observable, BehaviorSubject, of} from 'rxjs';
import {catchError, filter, finalize, take, switchMap} from 'rxjs/operators';
import {UserStoreService} from '../core/services/user-store.service';
import {LoginService} from '../core/services/login.service';
import {environment} from '../../environments/environment';
import {AccessReference} from '../shared/models/access-reference.model';
import {Utilities} from '../shared/utilities/utilities';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private userStore: UserStoreService,
    private loginService: LoginService,
  ) {
  }

  private AUTH_HEADER = 'Authorization';
  private token: AccessReference = null;
  private refreshTokenInProgress = false;
  private body: any;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.token = Utilities.getAccessReference();
    req = this.addAuthenticationToken(req);

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error && error.status === 401) {
          // this.loginService.logout();
          if (error.error.result === 600 || error.error.result === 666) {
            this.loginService.logout();
          } else {
            return throwError(error);
          }
          /*if (error.error.result === 600) {
            if (this.refreshTokenInProgress) {
              return this.refreshTokenSubject.pipe(
                filter(result => result !== null),
                take(1),
                switchMap(() => next.handle(this.addAuthenticationToken(req, true)))
              );
            } else {
              this.refreshTokenInProgress = true;
              this.refreshTokenSubject.next(null);

              return this.refreshAccessToken().pipe(
                switchMap((success: any) => {
                  this.refreshTokenSubject.next(success);
                  return next.handle(this.addAuthenticationToken(req, true));
                }),
                finalize(() => (this.refreshTokenInProgress = false))
              );
            }
          } else if (error.error.result === 666) {
            this.loginService.logout();
          } else {
            return throwError(error);
          }*/
        } else {
          return throwError(error);
        }
      })
    );
  }

  private refreshAccessToken(): Observable<any> {
    return new Observable<any>(
      observer => {
        this.loginService.refreshToken()
          .subscribe(
            response => {
              this.userStore.refreshAccessReference(response);
              observer.next(response);
            },
            error => {
              observer.next(error);
            }
          );
      });
  }

  private addAuthenticationToken(request: HttpRequest<any>, attempt = false): HttpRequest<any> {
    // tslint:disable-next-line:jsdoc-format
    /** se non ce il token quindi non sei loggoto non lo aggiunge **/
    if (attempt) {
      this.token = Utilities.getAccessReference();
    }

    if (!this.token) {
      return request;
    }

    // tslint:disable-next-line:jsdoc-format
    /**se la rotta non fa parte del flusso interno non aggiungo il token **/
    if (!request.url.match(environment.baseUrl)) {
      return request;
    }

    // tslint:disable-next-line:jsdoc-format
    /**in tutti gli altri caso aggiungo il token **/
    return request.clone({
      headers: request.headers.set(this.AUTH_HEADER, 'Bearer ' + this.token.accessToken)
    });
  }
}
