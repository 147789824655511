<div *ngIf="user">
  <mat-toolbar color="primary">
    <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon"
            *ngIf="user.userInfo.companyId !== '0'" (click)="menuActionSelectEventEmitter.emit()">
      <mat-icon *ngIf="user.userInfo.auth == 0 || user.userInfo.auth == 1">menu</mat-icon>
    </button>
    <img src="assets/ttauri.png" height="50px" alt="">
    <span class="example-spacer"></span>

    <div *ngIf="user.userInfo.auth == 0 || user.userInfo.auth == 1">
      <div *ngIf="companySelected">
        <button mat-raised-button (click)="openBottomSheet()"> {{companySelected.name}} </button>
      </div>
    </div>


    <button *ngIf="user.userInfo.companyId !== '0'" mat-icon-button class="example-icon favorite-icon"
            aria-label="Example icon-button with heart icon">
      <mat-icon *ngIf="user.userInfo.auth == 0 || user.userInfo.auth == 1 " aria-hidden="false" mat-button
                [matMenuTriggerFor]="menu" [matBadge]="alerts.length.toString()" matBadgeColor="warn">notifications
      </mat-icon>
      <mat-menu #menu="matMenu">
        <ng-container *ngIf="!alerts || alerts.length == 0">
          <p>non ci sono notifiche</p>
        </ng-container>
        <ng-container *ngIf="alerts && alerts.length > 0">
          <mat-divider></mat-divider>
          <br>
          <button mat-raised-button color="primary" (click)="goToAllAlerts()">Tutte</button>
          <br><br>
          <mat-divider></mat-divider>
          <ng-container *ngFor="let alert of alerts">
            <div>
              <p><b>Tipo: </b>  {{alert.type == 'absence' ? 'Assenza' : alert.type == 'delay' ? 'Ritardo' : 'Evento'}}
              </p>
              <p><b>Impiegato: </b>  {{alert.sender.name}} </p>
              <p><b>Email: </b>  {{alert.sender.email}} </p>
            </div>
            <mat-divider></mat-divider>
          </ng-container>
        </ng-container>
      </mat-menu>
    </button>

    <button *ngIf="user && user.userInfo.companyId !== '0'" mat-icon-button class="example-icon" (click)="userActions()"
            aria-label="Example icon-button with share icon">
      <mat-icon aria-hidden="false" aria-label="Example home icon">face</mat-icon>
    </button>

    <button *ngIf="user" mat-icon-button class="example-icon" (click)="logOut()"
            aria-label="Example icon-button with share icon">
      <mat-icon aria-hidden="false" aria-label="Example home icon">login</mat-icon>
    </button>
  </mat-toolbar>
</div>


<div *ngIf="!user">
  <mat-toolbar color="primary">
    <img src="assets/ttauri.png" height="50px" alt="">
    <span class="example-spacer"></span>
  </mat-toolbar>
</div>
