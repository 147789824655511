import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {LoginService} from '../../services/login.service';
import {Subject, Subscription} from 'rxjs';
import {AlertStoreService} from '../../services/alert-store.service';
import {UserStoreService} from '../../services/user-store.service';
import {EmployeeRoutesModel} from '../../../employee/employee.routes.model';
import {Router} from '@angular/router';
import {AdminRoutesModel} from '../../../admin/admin.routes.model';
import {MatDialog} from '@angular/material/dialog';
import {UserActionDialogComponent} from './user-action-dialog/user-action-dialog.component';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {BottomSheetOverviewComponent} from './bottom-sheet-overview/bottom-sheet-overview.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() user: any;
  @Input() companySelected: any;
  @Output() menuActionSelectEventEmitter: EventEmitter<void> = new EventEmitter<void>();
  private alertSubscription: Subscription;
  private userSubscription: Subscription;
  private readonly onDestroy = new Subject<void>();
  alerts: any [] = [];

  constructor(
    private bottomSheet: MatBottomSheet,
    private router: Router,
    public dialog: MatDialog,
    private userStoreService: UserStoreService,
    private loginService: LoginService,
    private alertStoreService: AlertStoreService,
  ) {
    this.subjectAlert();
    this.subjectUser();
    this.getAllCompanies();
  }

  ngOnInit(): void {
  }

  getAllCompanies() {
    if (this.userStoreService.getUSer()?.userInfo) {
      this.loginService.getAllCompanies().subscribe(resp => {
        if (resp) {
          this.userStoreService.setAllCompanies(resp);
        }
      });
    }
  }

  subjectAlert() {
    this.alertSubscription = this.alertStoreService.getAlertObservable()
      .subscribe(
        (alerts: any[]) => {
          this.alerts = null;
          this.alerts = alerts;
        }
      );
  }

  subjectUser() {
    this.userSubscription = this.userStoreService.getUserObservable()
      .subscribe(
        (user) => {
          this.getAllCompanies();
        }
      );
  }

  logOut() {
    this.loginService.logout();
  }

  userActions() {
    const face = window.document.body.querySelectorAll('#face');
    if(!face || face.length === 0){
      const dialogRef = this.dialog.open(UserActionDialogComponent, {
        data: {}
      });
      dialogRef.afterClosed().subscribe((result: any[]) => {
        if (result) {
          this.loginService.logout();
        }
      });
    }
  }

  ngOnDestroy() {
    this.onDestroy.next();
  }

  goToAllAlerts() {
    const user = this.userStoreService.getUSer();
    if (user.userInfo.auth == '0' || user.userInfo.auth == '1') {
      this.router.navigate([`${AdminRoutesModel.ADMIN}/${AdminRoutesModel.ALERTS}`]);
    }
    if (user.userInfo.auth == '2' || user.userInfo.auth == '3') {
      this.router.navigate([`${EmployeeRoutesModel.EMPLOYEE}/${EmployeeRoutesModel.NOTIFICATION}`]);
    }
  }

  openBottomSheet(): void {
    this.bottomSheet.open(BottomSheetOverviewComponent);
  }
}

