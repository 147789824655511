export enum EmployeeRoutesModel {
  EMPLOYEE = 'employee',
  HOME = 'home',
  DASHBOARD = 'dashboard',
  NOTIFICATION = 'notification',
  ABSENCES = 'absences',
  TEAM = 'team',
  LOAN = 'prestito',
  EDIT = 'list-events',
  NEW = 'new-event',
  BASEURL = 'employee/home',
  QRCODE = 'qrcode',
  READ = 'read',
  QRRESUL = 'qrResult',
  LISTTIMB = 'listTimb'
}
