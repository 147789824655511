import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-form-accordion-container',
  templateUrl: './form-accordion-container.component.html',
  styleUrls: ['./form-accordion-container.component.css']
})
export class FormAccordionContainerComponent implements OnInit {

  @Output() public customOnSubmitEventEmitter: EventEmitter<Event> = new EventEmitter();
  @Output() public customOnExpandEventEmitter: EventEmitter<boolean> = new EventEmitter();
  @Output() public customOnResetFilterEventEmitter: EventEmitter<Event> = new EventEmitter();

  @Input() title: string;
  @Input() searchButtonText: string;
  @Input() clearFilterButtonText: string;
  @Input() mainIconClass: string;
  @Input() isExpanded: boolean;
  @Input() haveActivatedFilters: boolean;
  @Input() formInvalid: boolean;
  @Input() formId: string;




  constructor() {
  }

  ngOnInit(): void {
  }

}
