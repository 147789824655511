export enum AdminRoutesModel {
  EMPLOYEE = 'employee',
  ADMIN = 'admin',
  HOME = 'home',
  JOBS = 'jobs',
  CLIENTLIST = 'client',
  WORKS = 'works',
  DETAIL = 'detail',
  DETAILS = 'details',
  DOWNLOAD = 'download',
  NEW = 'new',
  TRANSFERS = 'transfers',
  LIST = 'list',
  CALENDAR = 'calendario',
  ALERTS = 'alerts',
  ABSENCES = 'absences',
  COMPANY = 'company',
  QRMANAGEMENT = 'qrManagement',
  CREATE = 'creates',
}
