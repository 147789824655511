export interface UserI {
  token: string;
  userInfo: UserInfo;
}

export interface UserInfo {
  id: string;
  email: string;
  password: string;
  username: string;
  image: string;
  role: Role;
  auth: any;
  companyId: string;
  companyName: string;
}

export class User implements UserI {
  token: string;
  userInfo: UserInfo;

  static newUSerByResponse(response: any) {
    const user: User = new User();
    user.token = response.token;
    user.userInfo = response.userInfo;
    return user;
  }
}


export enum Role {
  ADMIN = 'admin',
  SUPERADMIN = 'superAdmin',
  EMPLOYEE = 'employee',
  TEAMLEADER = 'teamLeader'
}

