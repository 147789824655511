export enum TableModel {
  ruolo = 'ruolo',
  status = 'stato',
  startTime = 'dal',
  companyName = 'Societa',
  dateProg = 'Data',
  signatureFrom = 'firma ingresso',
  signatureTo = 'firma uscita',
  creation_date = 'data',
  endTime = 'al',
  name = 'nome',
  phone = 'telefono',
  lastname = 'cognome',
  clientName = 'nome Cliente',
  zoneAddress = 'indirizzo zona',
  zoneName = 'nome zona',
  certification = 'certificato',
  description = 'descrizione',
  actionStartDate = 'Data Inizio Intervento',
  actionEndDate = 'Data Fine Intervento',
  date = 'Data',
  hourFrom = 'Dalle',
  hourTo = 'Alle',
  email = 'email',
  link = 'link',
  modifica = 'modifica',
  scarica = 'scarica',
  cancella = 'cancella',
  aggiungi = 'aggiungi',
  destinazione = 'destinazione',
  desctiption = 'descrizione',
  creaQr = 'creaQr',
  listaQr = 'listaQr'
}

export enum ButtonsTableName {
  link = 'Link Cliente',
  modifica = 'Modifica',
  cancella = 'Cancella',
  creaQr = 'Crea QrCode',
  listaQr = 'Lista QrCode',
  scarica = 'Scarica'
}

export enum ButtonsTableColor {
  link = 'white',
  modifica = 'primary',
  scarica = 'primary',
  cancella = 'warn',
  creaQr = 'primary',
  listaQr = 'white'
}
