<mat-expansion-panel *ngIf="isFilterable && !isModal">
  <mat-expansion-panel-header expandedHeight="80px" collapsedHeight="80px">
    <mat-panel-title>
      <ng-container>Filtra la ricerca</ng-container>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <mat-form-field>
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" #input>
  </mat-form-field>
</mat-expansion-panel>

<ng-container *ngIf="isFilterable && isModal">
  <mat-form-field>
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" #input>
  </mat-form-field>
</ng-container>
<br><br>

<div class="mat-elevation-z8">
  <mat-table [dataSource]="dataSource" matSort  matSortActive="{{labelSort}}" matSortDirection="{{sortV}}">
    <ng-container *ngFor="let label of displayedColumns">
      <ng-container matColumnDef="{{label}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{getlabel(label)}} </mat-header-cell>
        <mat-cell *matCellDef="let row" (click)="onClickCell(row)">
          <ng-container *ngIf="label != 'modifica' &&
          label != 'cancella' &&
          label != 'date'&&
          label != 'startTime'&&
          label != 'creation_date'&&
          label != 'endTime'&&
          label != 'auth' &&
          label != 'dateProg' &&
          label != 'signatureTo' &&
          label != 'actionStartDate' &&
          label != 'actionEndDate' &&
          label != 'signatureFrom' &&
          label != 'status' ">
            {{row[label]}}
          </ng-container>
          <ng-container *ngIf="label == 'date' || label == 'startTime' || label == 'endTime'
          || label === 'actionStartDate' || label === 'actionEndDate' || label == 'creation_date'
          || label === 'signatureTo' || label === 'signatureFrom' ">
            {{row[label] == 'n/d' ? row[label] : getDate(row[label])  | date: 'dd/MM/yy  HH: mm' }}
          </ng-container>

          <ng-container *ngIf="label == 'dateProg'  ">
            {{row[label] == 'n/d' ? row[label] : row[label] | date: 'dd/MM/yy' }}
          </ng-container>

          <ng-container *ngIf="label == 'status'">
            {{row[label] == 0 ? 'In attesa approvazione' : 'Approvato'  }}
          </ng-container>
          <ng-container *ngIf="label == 'auth'">
            {{row[label] == 3 ? 'Capo Squadra' : '--' }}
          </ng-container>
          <ng-container *ngIf="label == 'aggiungi'">
            <mat-checkbox (change)="onchangeCheckbox($event, row)"></mat-checkbox>
          </ng-container>

          <ng-container *ngIf="label == 'link'">
            <button mat-raised-button color="white" (click)="linkEventEmitter.emit(row)">Link Cliente</button>
          </ng-container>

          <ng-container *ngIf="label == 'creaQr'">
            <button mat-raised-button color="primary" (click)="creaQrEventEmitter.emit(row)">Crea QR-Code</button>
          </ng-container>

          <ng-container *ngIf="label == 'listaQr'">
            <button mat-raised-button color="white" (click)="listaQrEventEmitter.emit(row)">Lista QR-Code</button>
          </ng-container>

          <ng-container *ngIf="label == 'modifica'">
            <button mat-raised-button color="primary" (click)="editEventEmitter.emit(row)">Modifica</button>
          </ng-container>
          <ng-container *ngIf="label == 'cancella'">
            <button mat-raised-button color="warn" (click)="deleteEventEmitter.emit(row)">Cancella</button>
          </ng-container>
        </mat-cell>
      </ng-container>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
  <!--<mat-paginator *ngIf="isPaginable" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>-->
</div>

