export class AccessReference {
  accessToken: string;


  constructor(model?: any) {
    if (model) {
      this.accessToken = model.access_token;

    }
  }
}
