import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {DeviceDetectorModule} from 'ngx-device-detector';
import {FormAccordionContainerComponent} from './components/form-accordion-container/form-accordion-container.component';
import {AngularMaterialModule} from '../meterial/angular-material.module';
import {MatExpansionModule} from '@angular/material/expansion';
import {GenericTableComponent} from './components/generic-table/generic-table.component';
import {ConfirmDialogComponent} from './components/confirm-dialog/confirm-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {DateCustomFormatPipe, DateUtcPipe} from './pipes/date-utc.pipe';
import {MaterialTableComponent} from './components/material-table/material-table.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSliderModule} from '@angular/material/slider';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatStepperModule} from '@angular/material/stepper';
import {CalendarModule, DateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSortModule} from '@angular/material/sort';
import {LoaderComponent} from './components/loader/loader.component';
import {PaginatorComponent} from './components/paginator/paginator/paginator.component';
import {AddFileComponent} from './components/add-file/add-file.component';
import {TableComponent} from './components/table/component/table/table.component';


@NgModule({
  declarations: [
    FormAccordionContainerComponent,
    GenericTableComponent,
    ConfirmDialogComponent,
    DateUtcPipe,
    DateCustomFormatPipe,
    MaterialTableComponent,
    LoaderComponent,
    PaginatorComponent,
    AddFileComponent,
    TableComponent,
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    DeviceDetectorModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSliderModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    MatTabsModule,
    MatSortModule,
  ],
  exports: [
    FormAccordionContainerComponent,
    GenericTableComponent,
    ConfirmDialogComponent,
    DateUtcPipe,
    DateCustomFormatPipe,
    MaterialTableComponent,
    LoaderComponent,
    PaginatorComponent,
    AddFileComponent,
    TableComponent
  ]
})
export class SharedModule {
}
