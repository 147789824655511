import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {DateUtilities} from "../../utilities/date-utilities.model";

@Component({
  selector: 'app-material-table',
  templateUrl: './material-table.component.html',
  styleUrls: ['./material-table.component.css']
})
export class MaterialTableComponent implements OnInit, OnChanges {

  @Input() displayedColumns: string[];
  @Input() elements: any[];
  @Input() isFilterable = true;
  @Input() isModal = false;
  @Input() isPaginable = true;
  @Input() isMultiple = false;

  @Input() labelSort = '';
  @Input() sortV = 'desc';

  @Output() public editEventEmitter: EventEmitter<any> = new EventEmitter();
  @Output() public deleteEventEmitter: EventEmitter<any> = new EventEmitter();
  @Output() public elementSelectedEventEmitter: EventEmitter<any> = new EventEmitter();
  @Output() public dataSourceReturnEventEmitter: EventEmitter<any[]> = new EventEmitter();
  @Output() public linkEventEmitter: EventEmitter<any> = new EventEmitter();
  @Output() public creaQrEventEmitter: EventEmitter<any> = new EventEmitter();
  @Output() public listaQrEventEmitter: EventEmitter<any> = new EventEmitter();

  dataSource: MatTableDataSource<any>;
  dataSourceReturn: any[];

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor() {
  }

  ngOnInit(): void {
    this.dataSourceReturn = [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
    if (changes.elements && changes.elements.currentValue) {
      this.dataSource = new MatTableDataSource(this.elements);
      this.dataSource.sort = this.sort;
    }
    if (changes.isPaginable && changes.isPaginable.currentValue) {
      this.dataSource.paginator = this.paginator;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onchangeCheckbox(event: MatCheckboxChange, element: any) {
    if (event.checked) {
      this.dataSourceReturn.push(element);
    } else {
      this.dataSourceReturn = this.dataSourceReturn.filter(el => el !== element);
    }
    this.dataSourceReturnEventEmitter.emit(this.dataSourceReturn);
  }

  onClickCell(row: any) {
    if (!this.isMultiple) {
      this.elementSelectedEventEmitter.emit(row);
    }
  }

  getDate(d) {
    return DateUtilities.getDate(d);
  }

  getlabel(label) {
    if (label == 'auth') {
      return 'ruolo';
    }
    if (label == 'status') {
      return 'stato';
    }
    if (label == 'startTime') {
      return 'dal';
    }
    if (label == 'companyName') {
      return 'Societa';
    }


    if (label == 'dateProg') {
      return 'Data';
    }


    if (label == 'signatureFrom') {
      return 'firma ing.';
    }


    if (label == 'signatureTo') {
      return 'firma usci.';
    }


    if (label == 'creation_date') {
      return 'data';
    }


    if (label == 'endTime') {
      return 'al';
    }
    if (label == 'name') {
      return 'nome';
    }
    if (label == 'phone') {
      return 'telefono';
    }
    if (label == 'lastname') {
      return 'cognome';
    }
    if (label == 'clientName') {
      return 'nome Cliente';
    }
    if (label == 'zoneAddress') {
      return 'indirizzo zona';
    }
    if (label == 'zoneName') {
      return 'nome zona';
    }
    if (label == 'certification') {
      return 'certificato';
    }
    if (label == 'creation_date') {
      return 'data';
    }
    if (label == 'description') {
      return 'luogo';
    }
    if (label == 'actionStartDate') {
      return 'Data Inizio Intervento';
    }
    if (label == 'actionEndDate') {
      return 'Data Fine Intervento';
    }
    if (label == 'date') {
      return 'Data';
    }
    if (label == 'hourFrom') {
      return 'Dalle';
    }
    if (label == 'hourTo') {
      return 'Alle';
    }


    return label;
  }

}
