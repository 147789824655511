import { ListAllComponent } from './client/list-all/list-all.component';
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import { ROOT_ADMIN, ROOT_EMPLOYEE, ROOT_CLIENT } from './app-routing-roots';
import {ModulesLoadingGuard} from './modules-loading.guard';


const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: ROOT_EMPLOYEE
  },
  {
    path: ROOT_ADMIN,
    canLoad: [ModulesLoadingGuard],
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
  },
  {
    path: ROOT_EMPLOYEE,
    canLoad: [ModulesLoadingGuard],
    loadChildren: () => import('./employee/employee.module').then(m => m.EmployeeModule),
  },
  {
    path: ROOT_CLIENT,
    canLoad: [ModulesLoadingGuard],
    loadChildren: () => import('./client/client.module').then(m => m.ClientModule),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
