import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Router, NavigationStart} from '@angular/router';
import {Alert, AlertType} from '../../model/alert.model';
import {NotificationService} from '../../services/notification.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-inner-message',
  templateUrl: './inner-message.component.html',
  styleUrls: ['./inner-message.component.css']
})
export class InnerMessageComponent implements OnInit, OnDestroy {

  @Input() id = 'default-alert';
  @Input() fade = true;

  alerts: Alert[] = [];
  alertSubscription: Subscription;
  routeSubscription: Subscription;


  constructor(
    private router: Router,
    private alertService: NotificationService
  ) {
  }

  ngOnInit() {
    this.alertSubscription = this.alertService.onAlert(this.id)
      .subscribe(alert => {
        if (!alert.message) {
          this.alerts = this.alerts.filter(x => x.options.keepAfterRouteChange);

          this.alerts.forEach(x => delete x.options.keepAfterRouteChange);
          return;
        }
        this.alerts.push(alert);

        if (alert.options.autoClose) {
          setTimeout(() => this.removeAlert(alert), 2000);
        }
      });

    this.routeSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.alertService.clear(this.id);
      }
    });
  }

  ngOnDestroy() {
    this.alertSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
  }

  removeAlert(alert: Alert) {
    if (!this.alerts.includes(alert)) {
      return;
    }
    this.alerts = this.alerts.filter(x => x !== alert);
  }

  cssClass(alert: Alert) {
    if (!alert) {
      return;
    }
    const classes = ['alert', 'alert-dismissable'];

    const alertTypeClass = {
      [AlertType.Success]: 'alert alert-success',
      [AlertType.Error]: 'alert alert-danger',
      [AlertType.Info]: 'alert alert-info',
      [AlertType.Warning]: 'alert alert-warning'
    };

    classes.push(alertTypeClass[alert.type]);


    return classes.join(' ');
  }
}
