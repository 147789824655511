import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.css']
})
export class PaginatorComponent implements OnInit {

  @Input() totalItems : number;
  @Input() itemPerPage : number = 10;
  @Output() changePageEventEmitter = new EventEmitter<any>();
  

  constructor() { }

  ngOnInit(): void {}

  page(event) {
    this.changePageEventEmitter.emit(event);
  }




}
