import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LoginService} from '../../services/login.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserStoreService} from '../../services/user-store.service';
import {LoginModel} from '../../model/login.model';
import {Utilities} from '../../../shared/utilities/utilities';
import { DialogData} from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  signinForm: FormGroup;
  loggedIn: boolean;
  messageKO = 'Errore: Utente non trovato!!';
  messageOK = 'Benvenuto ';
  error = null;
  hide = true;
  token = ''

  showPage = false;

  constructor(
    private fb: FormBuilder,
    private loginService: LoginService,
    private userStore: UserStoreService,
    private activeRoute: ActivatedRoute,
    public dialog: MatDialog,
    private  route: Router) {
    const currentUser = this.userStore.getUSer();
    if (currentUser) {
      this.route.navigateByUrl('home');
    }
  }

  ngOnInit() {
    this.signinForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }


  public onKeyPress(event: any) {
    if (Utilities.pressedEnter(event)) {
    }
  }

  login() {
    const login = this.signinForm.getRawValue();
    const modelToLogin: LoginModel = {
      email: login.email,
      password: login.password
    };
    this.loginService.login(modelToLogin, this.messageKO, this.messageOK);
  }

  forgotPassword() {
    const dialogRef = this.dialog.open(ForgotPasswordComponent, {
      data: {}
    });
    dialogRef.afterClosed().subscribe((result: DialogData) => {});
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (Object as any).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

}
