
<div class="row mt-2 mb-2 foto">
  <div class="col-6 offset-3">
    <div [ngClass]="image?.img ? 'cover-image' : ''" class="container-icon" [ngStyle]="image.img ? {'background-image': 'url(' + image.img + ')'} : ''">
      <div *ngIf="!image?.img" class="icon icon-user icon-second-color position-icon-user"></div>
      <div class="">
        <label class="">
          <input type="file" id="file" (change)="handleFileInput($event.target['files'])"/>
          <div class="icon-add-file" ></div>
        </label>
      </div>
    </div>
  </div>
</div>





