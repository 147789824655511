import {Role} from './user';
import {CustomMenuModel} from './custom-menu.model';
import {AdminPagesModel} from '../../admin/model/admin-pages.model';
import {EmployeePagesModel} from '../../employee/model/employee-pages.model';

export abstract class GetMenuForUserModel {
  public static getMenuForUSer(userType: Role): CustomMenuModel [] {

    let menu: CustomMenuModel[] = [];
    switch (userType) {
      case Role.ADMIN: {
        menu = AdminPagesModel.adminMenu;
        break;
      }
      case Role.EMPLOYEE: {
        menu = EmployeePagesModel.employeeMenu;
      }
    }
    return menu;

  }
}
