import {EventEmitter, Output} from '@angular/core';
import {ButtonsTableColor, ButtonsTableName, TableModel} from './table.model';

export class ButtonsManager {
  bottonsModel = ButtonsTableName;
  buttonsTableColor = ButtonsTableColor;
  buttonsArray = ['aggiungi', 'scarica', 'link', 'modifica', 'cancella', 'creaQr', 'listaQr'];

  @Output() public linkEventEmitter: EventEmitter<any> = new EventEmitter();
  @Output() public editEventEmitter: EventEmitter<any> = new EventEmitter();
  @Output() public deleteEventEmitter: EventEmitter<any> = new EventEmitter();
  @Output() public creaQrEventEmitter: EventEmitter<any> = new EventEmitter();
  @Output() public listaQrEventEmitter: EventEmitter<any> = new EventEmitter();
  @Output() public scaricaEventEmitter: EventEmitter<any> = new EventEmitter();

  isButton(label) {
    return this.buttonsArray.filter(el => el === label).length > 0;
  }

  buttonClick(element, label) {
    switch (label) {
      case TableModel.link: {
        this.linkEventEmitter.emit(element);
        break;
      }
      case TableModel.modifica: {
        this.editEventEmitter.emit(element);
        break;
      }
      case TableModel.cancella: {
        this.deleteEventEmitter.emit(element);
        break;
      }
      case TableModel.creaQr: {
        this.creaQrEventEmitter.emit(element);
        break;
      }
      case TableModel.listaQr: {
        this.listaQrEventEmitter.emit(element);
        break;
      }
      case TableModel.scarica: {
        this.scaricaEventEmitter.emit(element);
        break;
      }
    }
  }
}


