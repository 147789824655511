import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';

@Component({
  selector: 'app-generic-table',
  templateUrl: './generic-table.component.html',
  styleUrls: ['./generic-table.component.css']
})
export class GenericTableComponent implements OnInit {

  @Input() displayedColumns: string[];
  @Input() dataSource: MatTableDataSource<any>;
  @Output() elementSelectedEventEmitter: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild(MatSort, {static: true}) sort: MatSort;


  constructor() {
  }

  ngOnInit(): void {
    this.dataSource.sort = this.sort;
  }

}
