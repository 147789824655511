import {Utilities} from 'src/app/shared/utilities/utilities';
import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {DateUtilities} from '../../../../utilities/date-utilities.model';
import {TableModel} from '../model/table.model';
import {ButtonsManager} from '../model/buttonsManager';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent extends ButtonsManager implements OnChanges {
  @Input() displayedColumns: string[];
  @Input() elements: any[];
  @Input() isFilterable = true;
  @Input() isModal = false;
  @Input() isPaginable = true;
  @Input() isMultiple = false;
  @Input() labelSort = '';
  @Input() sortV = 'desc';

  @Output() public elementSelectedEventEmitter: EventEmitter<any> = new EventEmitter();
  @Output() public dataSourceReturnEventEmitter: EventEmitter<any[]> = new EventEmitter();

  model = TableModel;
  dataSource: MatTableDataSource<any>;
  dataSourceReturn: any[] = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.elements && changes.elements.currentValue) {
      this.dataSource = new MatTableDataSource<any>(this.elements);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getDate(d) {
    return DateUtilities.getDateForTable(d);
  }

  isValidDate(d): boolean {
    if (!Utilities.isVoid(d)) {
      return DateUtilities.isValidDate(d);
    } else {
      return false;
    }
  }

  onchangeCheckbox(event: MatCheckboxChange, element: any) {
    event.checked ? this.dataSourceReturn.push(element) : this.dataSourceReturn = this.dataSourceReturn.filter(el => el !== element);
    this.dataSourceReturnEventEmitter.emit(this.dataSourceReturn);
  }

  onClickCell(row: any) {
    if (!this.isMultiple) {
      this.elementSelectedEventEmitter.emit(row);
    }
  }

}
