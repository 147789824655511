import {CustomMenuModel} from '../../core/model/custom-menu.model';
import {EmployeeRoutesModel} from '../employee.routes.model';

export enum EmployeePages {
  HOME  = 'home',
  SIGNATURES = 'firme',
  ABSENCES = 'absences',
  NOTIFICATION = 'notification',
}


export abstract class EmployeePagesModel {
  public  static  employeeMenu: CustomMenuModel[] = [
    {
      label: EmployeePages.HOME,
      page: EmployeeRoutesModel.HOME,
      icon: 'dashboard'
    },
    {
      label: EmployeePages.ABSENCES,
      page: EmployeeRoutesModel.ABSENCES,
      icon:  'view_list'
    },
    {
      label: EmployeePages.NOTIFICATION,
      page: EmployeeRoutesModel.NOTIFICATION,
      icon:  'view_list'
    },
  ];
}
