

<mat-card>
  <mat-card-title>Login</mat-card-title>
  <br><br>
  <mat-card-content>
    <form [formGroup]="signinForm">
      <p>
        <mat-form-field appearance="fill">
          <mat-label>Enter your email</mat-label>
          <input matInput (keydown)="onKeyPress($event)" formControlName="email">
          <mat-error *ngIf="signinForm.controls['email'].hasError('email')">
            <ng-container>Formatto email sbagliato</ng-container>
          </mat-error>
          <mat-error *ngIf="signinForm.controls['email'].hasError('required')">
            <ng-container>email obbligatoria</ng-container>
          </mat-error>
        </mat-form-field>
      </p>

      <p>
        <mat-form-field appearance="fill">
          <mat-label>Enter your password</mat-label>
          <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" (keydown)="onKeyPress($event)">
          <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>
      </p>

      <p *ngIf="error" class="error">
        {{ error }}
      </p>

      <div class="button">
        <button mat-raised-button color="primary" (click)="login()">Login</button>
      </div>

    </form>
  </mat-card-content>

  <button mat-button color="primary" (click)="forgotPassword()">Password Dimenticata</button>
</mat-card>

