import {Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {CustomMenuModel} from '../../model/custom-menu.model';
import {Subject, Subscription} from 'rxjs';
import {UserStoreService} from '../../services/user-store.service';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {GetMenuForUserModel} from '../../model/get-menu-for-user.model';
import {Role} from '../../model/user';
import {AlertPollingService} from '../../services/alert-polling.service';
import {AlertStoreService} from '../../services/alert-store.service';

@Component({
  selector: 'app-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.css']
})
export class CoreComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav', {static: true}) sidenav: MatSidenav;

  opened = false;
  user: any = null;
  companySelected: any = null;
  private userSubscription: Subscription;
  private companySubscription: Subscription;


  mySubscription: Subscription;
  menu: CustomMenuModel [] = null;
  role: Role;
  alert: any[] = [];
  subEventPolling: Subscription;

  private readonly onDestroy = new Subject<void>();

  constructor(
    private alertPollingService: AlertPollingService,
    private userStore: UserStoreService,
    private alertStore: AlertStoreService
  ) {
  }

  ngOnInit() {
    this.user = this.userStore.getUSer();
    this.companySelected = this.userStore.getCompany();
    this.subjectUser();
    this.subjectCompany();
    this.resizeSidenav(null);
    this.getMenuForUSer();
  }

  startPollingAlert() {
    this.alertPollingService.settingPolling();
    this.subEventPolling = this.alertPollingService.pollingObservable
      .subscribe((result: any[]) => {
        this.alert = result;
        this.alertStore.init(this.alert);
      });
  }

  subjectUser() {
    this.userSubscription = this.userStore.getUserObservable()
      .pipe(
        debounceTime(250),
        distinctUntilChanged(),
        takeUntil(this.onDestroy),
      )
      .subscribe(
        (user: any) => {
          this.user = this.userStore.getUSer();
          if (this.user) {
            this.startPollingAlert();
            this.getMenuForUSer();
          }
          if (!this.user) {
            this.opened = false;
            this.menu = null;
            this.alertPollingService.stopPolling();
          }
        }
      );
  }

  subjectCompany() {
    this.companySubscription = this.userStore.getCompanyObservable()
      .pipe(
        debounceTime(250),
        distinctUntilChanged(),
        takeUntil(this.onDestroy),
      )
      .subscribe(
        (user: any) => {
          this.companySelected = this.userStore.getCompany();
        }
      );
  }

  getMenuForUSer() {
    if (this.user) {
      this.role = null;
      this.role = this.user.userInfo.auth == 0 || this.user.userInfo.auth == 1 ? Role.ADMIN : Role.EMPLOYEE;
      this.menu = GetMenuForUserModel.getMenuForUSer(this.role);
      this.resizeSidenav(null);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resizeSidenav(event);
  }

  resizeSidenav(event) {
    setTimeout(() => {
      const e = event ? event.target : window;
      if (e.innerWidth < 768) {
        this.sidenav.fixedTopGap = 55;
        this.opened = false;
      } else {
        this.sidenav.fixedTopGap = 65;
        this.opened = false;
      }
    }, 250);
  }

  isBiggerScreen() {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    return width < 768;
  }

  ngOnDestroy() {
    this.onDestroy.next();
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }

}
