import { Injectable } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private activeRoute: ActivatedRoute,
    private  route: Router,
    private http: HttpClient,
  ) {
  }

  getLatestAlerts(body: any): Observable<any> {
    const url = environment.baseUrl + 'getAllNotifications';
    return this.http.post<any>(url, body);
  }
}
