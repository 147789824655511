import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {ConvertInterceptor} from './conver.interceptor';
import {AuthInterceptor} from './auth.interceptor';
import {UserExistInterceptor} from './user-exist-interceptor';
import {ErrorInterceptor} from './error.interceptor';
import {UpPageInterceptor} from "./up-page.interceptor";



export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ConvertInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: UserExistInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: UpPageInterceptor, multi: true }

];
