<div id="face">

<mat-card>
  <mat-card-title>Cambio Password</mat-card-title>
  <br><br>
  <mat-card-content>
    <form [formGroup]="changePasswordForm">

      <p>
        <mat-form-field appearance="fill">
          <mat-label>Password corrente</mat-label>
          <input matInput [type]="hide ? 'password' : 'text'" formControlName="oldPassword">
          <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>
      </p>

      <p>
        <mat-form-field appearance="fill">
          <mat-label>Nuova Password</mat-label>
          <input matInput [type]="hide1 ? 'password' : 'text'" formControlName="newPassword">
          <button mat-icon-button matSuffix (click)="hide1 = !hide1" [attr.aria-label]="'hide password'"
                  [attr.aria-pressed]="hide1">
            <mat-icon>{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>
      </p>

      <p>
        <mat-form-field appearance="fill">
          <mat-label>Nuova Password</mat-label>
          <input matInput [type]="hide2 ? 'password' : 'text'" formControlName="copyNewPassword">
          <button mat-icon-button matSuffix (click)="hide2 = !hide2" [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hide2">
            <mat-icon>{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>
      </p>




      <p *ngIf="changePasswordKO" class="error">
        errore
      </p>



    </form>
  </mat-card-content>

</mat-card>

<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Chiudi</button>
  <button mat-button (click)="changePassword()">Ok</button>
</div>



</div>