<div class="core-container">
  <div class="header-container" >
    <app-header  [user]="user" [companySelected]="companySelected"  (menuActionSelectEventEmitter)="sidenav.toggle()"></app-header>
  </div>
  <div class="page-container">
    <mat-sidenav-container>
      <mat-sidenav  #sidenav [mode]="isBiggerScreen() ? 'over' : 'side'" [(opened)]="opened"
                   [fixedInViewport]="true" [fixedTopGap]>
        <app-menu *ngIf="user"  [menu]="menu" [user]="user"></app-menu>
      </mat-sidenav>
      <mat-sidenav-content>
        <div class="container-page">
          <router-outlet></router-outlet>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
    <app-inner-message></app-inner-message>
  </div>
</div>
