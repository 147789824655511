import { Component, OnInit } from '@angular/core';
import {MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {LoginService} from '../../../services/login.service';
import {UserStoreService} from '../../../services/user-store.service';

@Component({
  selector: 'app-bottom-sheet-overview',
  templateUrl: './bottom-sheet-overview.component.html',
  styleUrls: ['./bottom-sheet-overview.component.css']
})
export class BottomSheetOverviewComponent implements OnInit {

  companies: any[];

  constructor(
    private userStore: UserStoreService,
    private bottomSheetRef: MatBottomSheetRef<BottomSheetOverviewComponent>,
    private loginService: LoginService,
  ) {
  }

  openLink(event: MouseEvent, company: any): void {
    console.log('event', event);
    console.log('company', company);
    this.bottomSheetRef.dismiss();
    event.preventDefault();
    this.loginService.changeCompany(company);
  }

  ngOnInit(): void {
    this.getAllCompanies();
  }

  getAllCompanies() {
    this.companies = this.userStore.getAllCompanies();
    /*this.loginService.getAllCompanies().subscribe(resp => {
      if (resp) {
        this.companies = resp;
      }
    });*/
  }
}
