export class Alert {
  id: string;
  type: AlertType;
  message: string;
  autoClose: boolean;
  keepAfterRouteChange: boolean;
  fade: boolean;
  options: Options;

  constructor(init?: Partial <Alert>) {
    Object.assign(this, init);
  }
}

export enum AlertType {
  Success,
  Error,
  Info,
  Warning
}

export interface AlertModel {
  options: Options;
}

export interface Options  {
  autoClose: boolean;
  keepAfterRouteChange: boolean;
}


