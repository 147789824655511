import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {RoutesModel} from '../model/routes.model';
import {LoginComponent} from './components/login/login.component';
import {RootComponent} from './root/root.component';
import {PermissionGuard} from '../permission.guard';


const routes: Routes = [
  {
    path: `${RoutesModel.LOGIN}`,
    component: RootComponent,
    children: [
      {
        path: '',
        component: LoginComponent,
        canActivate: [PermissionGuard],
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class CoreRoutingModule { }



