import {Component, Input, OnInit} from '@angular/core';
import {AttachedFile} from '../../models/attached-file.model';
import {AlertOptionsModel} from '../../../core/model/alert.options.model';
import {NotificationService} from '../../../core/services/notification.service';

@Component({
  selector: 'app-add-file',
  templateUrl: './add-file.component.html',
  styleUrls: ['./add-file.component.css']
})
export class AddFileComponent implements OnInit {

  @Input() image: any = null;
  file: any;
  fileToUpload: File = null;

  constructor(public alertService: NotificationService) { }

  ngOnInit(): void {
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    this.file = files[0];
    AttachedFile.createFileContent(files[0]).subscribe((content: string) => {
      if (content) {
        AttachedFile.resizeImage(content).subscribe((result: any) => {
          this.image.img = null;
          this.image.img = result;
          this.image.file =  this.file;
        });
      } else {
        this.alertService.error('Errore: in caricamento file', AlertOptionsModel.getOptions(true, true));
      }
    });
  }
}
