import {AlertModel} from './alert.model';

export abstract class AlertOptionsModel {

  public static getOptions(isAutoClose = false, isKeepAfterRouteChange = true): AlertModel {
    return  {
      options: {
        autoClose: isAutoClose,
        keepAfterRouteChange: isKeepAfterRouteChange
      }
    };
  }
}

