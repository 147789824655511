import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LoginService} from '../../../services/login.service';
import {AlertOptionsModel} from '../../../model/alert.options.model';
import {NotificationService} from '../../../services/notification.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordForm: FormGroup;

  constructor(
    private loginService: LoginService,
    public alertService: NotificationService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ForgotPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any ) {}

  ngOnInit(): void {
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  newPassword() {
    if (!this.forgotPasswordForm.valid) {
      return;
    }
    const body = this.forgotPasswordForm.getRawValue();
    this.loginService.forgotPassword(body)
      .subscribe((response: any) => {
        this.alertService.success('Richiesta inviata', AlertOptionsModel.getOptions(true, true));
        this.dialogRef.close();
      });
  }
}
