import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {UserStoreService} from './user-store.service';
import {LoginModel} from '../model/login.model';
import {environment} from '../../../environments/environment';
import {User} from '../model/user';
import {NotificationService} from './notification.service';
import {AlertOptionsModel} from '../model/alert.options.model';
import {Observable} from 'rxjs';
import { AlertPollingService } from './alert-polling.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  userGetting = false;

  constructor(
    public alertService: NotificationService,
    private activeRoute: ActivatedRoute,
    private route: Router,
    private http: HttpClient,
    private userStore: UserStoreService,
    private alertPollingService: AlertPollingService
  ) {
  }


  login(loginModel: LoginModel, messageKO: string, messageOK: string) {
    const url = environment.baseUrl + 'loginUser';
    this.http.post<User>(url, loginModel)
      .subscribe((response: User) => {
        if (response && response.userInfo) {
          const message = messageOK + ':  ' + response.userInfo.email;
          this.alertService.success(message, AlertOptionsModel.getOptions(true, true));
          const user = User.newUSerByResponse(response);
          this.userStore.init(user);
          const company = {
            id: user.userInfo.companyId,
            name: user.userInfo.companyName
          };
          this.userStore.setCompany(company);
          this.goToPage(user);
        } else {
          this.alertService.error(messageKO, AlertOptionsModel.getOptions(true, false));
        }
      });
  }



  loginWithToken(token: string, messageKO: string, messageOK: string) {
    const url = environment.baseUrl + 'loginUser';
    this.http.post<User>(url, token)
      .subscribe((response: User) => {
        if (response && response.userInfo) {
          const message = messageOK;
          this.alertService.success(message, AlertOptionsModel.getOptions(true, true));
          const user = User.newUSerByResponse(response);
          this.userStore.init(user);
          const company = {
            id: user.userInfo.companyId,
            name: user.userInfo.companyName
          };
          this.userStore.setCompany(company);
          this.goToPage(user);
        } else {
          this.alertService.error(messageKO, AlertOptionsModel.getOptions(true, false));
        }
      });
  }

  loginWhithCompany(body: any): Observable<any> {
    const url = environment.baseUrl + 'loginCompanyById';
    return this.http.post<string>(url, body);
  }

  refreshToken(): Observable<string> {
    const url = environment.baseUrl + 'getRefreshedToken';
    return this.http.get<string>(url);
  }

  getAllCompanies(): Observable<any[]> {
    const url = environment.baseUrl + 'getAllCompanies';
    return this.http.get<any[]>(url);
  }

  getUserByToken(token: string) {
    if (!this.userGetting) {
      this.userGetting = true;
      this.requestUser(token);
    }
  }

  public usrByT(): Observable<any> {
    console.log('usrByT');
    const url = environment.baseUrl + 'getUserByToken';
    return this.http.post<string>(url, {});
  }


  private requestUser(token: string) {
    console.log('requestUser');
    const url = environment.baseUrl + 'getUserByToken';
    return this.http.post<any>(url, {})
      .subscribe((response: User) => {
        if (response.userInfo) {
          this.userGetting = false;
          const user = User.newUSerByResponse(response);
          this.userStore.init(user);
          const company = {
            id: user.userInfo.companyId,
            name: user.userInfo.companyName
          };
          this.userStore.setCompany(company);
        } else {
          this.logout();
        }
      });
  }

  getUSerForModule(token): Observable<string> {
    console.log('getUSerForModule');
    const url = environment.baseUrl + 'getUserByToken';
    return this.http.post<string>(url, {});
  }

  goToPage(user) {
    if (user) {
      // 0 admin
      // 1 super admin
      // 2 dipendente
      // 3 capo squadra

      if (user.userInfo.auth == 0 || user.userInfo.auth == 1) {
        this.route.navigateByUrl('admin');
      } else if (user.userInfo.auth == 2 || user.userInfo.auth == 3) {
        this.route.navigateByUrl('employee');
      }
      else {
        this.logout();
        this.alertService.error('ruolo utente non trovato', AlertOptionsModel.getOptions(true, true));
      }
    }
  }

  logout() {
    this.alertPollingService.stopPolling();
    this.userStore.delete();
    this.route.navigateByUrl('login');
  }

  changePassWord(body: any) {
    const url = environment.baseUrl + 'resetPassword';
    return this.http.post<User>(url, body);
  }

  forgotPassword(body: any) {
    const url = environment.baseUrl + 'forgottenPassword';
    return this.http.post<User>(url, body);
  }

  changeCompany(company: any) {
    const body = {companyId: company.id};
    const url = environment.baseUrl + 'changeCompany';
    this.http.post<User>(url, body).subscribe((response: User) => {
      if (response && response.userInfo) {
        const message = 'success';
        this.alertService.success(message, AlertOptionsModel.getOptions(true, true));
        const user = User.newUSerByResponse(response);
        this.userStore.init(user);
        this.userStore.setCompany(company);
        this.goToPage(user);
      } else {
        this.alertService.error('Error', AlertOptionsModel.getOptions(true, false));
      }
    });
  }


}
