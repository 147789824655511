import {Utilities} from './utilities';

declare const require: any;
const moment = require('moment');
moment.locale('it');

export class DateUtilities {
  private static IT_MONTH_NAMES = {
    'long': 'Gennaio_Febbraio_Marzo_Aprile_Maggio_Giugno_Luglio_Agosto_Settembre_Ottobre_Novembre_Dicembre'.split('_'),
    'short': 'Gen_Feb_Mar_Apr_Mag_Giu_Lug_Ago_Set_Ott_Nov_Dic'.split('_'),
    'narrow': 'G_F_M_A_M_G_L_A_S_O_N_D'.split('_')
  };

  private static IT_DAY_OF_WEEK_NAMES = {
    'long': 'Domenica_Lunedì_Martedì_Mercoledì_Giovedì_Venerdì_Sabato'.split('_'),
    'short': 'Dom_Lun_Mar_Mer_Gio_Ven_Sab'.split('_'),
    'narrow': 'D_L_M_M_G_V_S'.split('_')
  };

  private static IT_FIRST_DAY_OF_WEEK = 1;

  public static onlyDate = 'L'; // es per l'italia DD/MM/AAAA
  public static onlyTime = 'LTS'; // es per l'italia hh:mm:ss
  public static dateAndTime = 'L LTS'; // es per l'italia hh:mm:ss

  public static today = new Date(new Date().setHours(0, 0, 0, 0));
  public static todayMorning = new Date(new Date().setHours(7, 0, 0));
  public static todayAfternoon = new Date(new Date().setHours(12, 0, 0, 0));
  public static todayEvening = new Date(new Date().setHours(16, 0, 0, 0));
  public static todayEveningMax = new Date(new Date().setHours(18, 59, 59, 0));
  public static olderDate = new Date(new Date().setFullYear(1970));
  public static olderUtcDate = DateUtilities.getUTCStringFromDate(new Date(1900, 1, 1, 0, 0, 0));
  public static newerUtcDate = DateUtilities.getUTCStringFromDate(new Date(2099, 12, 31, 23, 59, 59));

  public static getDateNow(): string {
    const date_now = new Date();
    return moment(date_now).format('L') + ' ' + moment(date_now).format('LTS');
  }

  public static getNow(): Date {
    return new Date();
  }

  public static isMoment(value): boolean {
    return  moment.isMoment(value);
  }

  public static getDateNormalStringAMG(d: any): any {
    return (moment(d)).format('YYYY-MM-DD');
  }

  public static getDateWithoutTimes(date: string): string {
    if (!Utilities.isVoid(date)) {
      if (date.indexOf(' ') > 0) {
        return date.substring(0, date.indexOf(' '));
      } else {
        return date;
      }
    } else {
      return '';
    }
  }

  public static getDateUTCFromString(d: any): Date {
    if (!Utilities.isVoid(d)) {
      return new Date(moment(d).utc());
    }
    return null;
  }

  public static getDate(d: any): Date {
    if (!Utilities.isVoid(d)) {
      return new Date(moment(d));
    }
    return null;
  }


  public static getDateFromDatePicker(d: any): any {
      return moment(d).format('YYYY-MM-DD');
  }

  public static getDateForTable(d: any): Date {
    if (!Utilities.isVoid(d) && moment(d, true).isValid()) {
      return moment.utc(d).format('YYYY-MM-DD HH:mm:ss');
    }
    return d;
  }

  public static isValidDate(d: any): boolean {
    return !!moment(d, true).isValid();
  }

  public static getMomentUTC(d: any): any {
    return moment(d).utc();
  }

  public static getMoment(d: any): any {
    return moment(d);
  }

  public static getIsoStringFromDate(d: any): Date {
    if (!Utilities.isVoid(d)) {
      return moment.utc(d).format('YYYY-MM-DDTHH:mm:ss[Z]');
    }
    return null;
  }

  public static getStringDateFromDate(d: any): string {
    if (!Utilities.isVoid(d)) {
      return moment(d).format('YYYY-MM-DD');
    }
    return null;
  }

  public static getDayFromUTC(d: any): string {
    if (!Utilities.isVoid(d)) {
      return moment(d).format('DD/MM/YYYY');
    }
    return null;
  }

  public static getUTCStringFromDate(d: any): string | null {
    if (!Utilities.isVoid(d) && moment(d).isValid()) {
      return moment.utc(d).format('YYYY-MM-DDTHH:mm:ssZZ');
    }
    return null;
  }


  public static getUTCStringFromDate2(d: any): string | null {
    if (!Utilities.isVoid(d) && moment(d).isValid()) {
      return moment.utc(d).format('YYYY-MM-DD HH:mm:ss');
    }
    return null;
  }

  public static getUTCStringFromDateWithForm(d: any, format: any): string | null {
    if (!Utilities.isVoid(d) && !Utilities.isVoid(format) && moment(d, format, true).isValid()) {
      return moment.utc(d, format, true).format('YYYY-MM-DDTHH:mm:ssZZ');
    }
    return null;
  }

  public static compareDateIsAfter(d1: Date | string, d2: Date | string): boolean {
    if (!Utilities.isVoid(d1) && !Utilities.isVoid(d2)) {
      if (typeof d1 === 'string') {
        d1 = moment(d1, 'L, LTS');
      }
      if (typeof d2 === 'string') {
        d2 = moment(d2, 'L, LTS');
      }
      return moment(d1).isAfter(d2);
    }
    return false;
  }

  public static compareDateIsBefore(d1: Date, d2: Date): boolean {
    if (!Utilities.isVoid(d1) && !Utilities.isVoid(d2)) {
      return moment(d1).isBefore(d2);
    }
    return false;
  }

  public static compareDateIsSame(d1: Date, d2: Date): boolean {
    if (!Utilities.isVoid(d1) && !Utilities.isVoid(d2)) {
      return moment(d1).isSame(d2);
    }
    return false;
  }


  public static getDAteCustomPErCliente(d1: Date): any {
    return (moment(d1)).format('YYYY-MM-DD HH:mm:ss');
  }


  public static isValid(d: any): boolean {
    if (!Utilities.isVoid(d)) {
      return moment(d).isValid();
    }
  }

  public static getDateFromString(d: string, mFormat?: string): Date | null {
    let date: Date = null;
    if (!Utilities.isVoid(d) && d.length > 0) {
      if (Utilities.isVoid(mFormat)) {
        mFormat = 'L LTS';
      }
      date = new Date(moment(DateUtilities.getDateWithoutTimes(d), mFormat));
      if (moment(date, mFormat, true).isValid()) {
        return date;
      }
    }
    return date;
  }

  public static convertUtcDate(date: string, mFormat?: string): string {
    if (!Utilities.isVoid(date)) {
      if (Utilities.isVoid(mFormat)) {
        mFormat = 'L LTS';
      }
      if (moment(date).isValid()) {
        return moment(date).utc().format(mFormat);
      }
    }
    return '';
  }

  public static convertDate(date: string, mFormat?: string): string {
    if (!Utilities.isVoid(date)) {
      if (Utilities.isVoid(mFormat)) {
        mFormat = 'L LTS';
      }
      if (moment(date).isValid()) {
        return moment(date).format(mFormat);
      }
    }
    return '';
  }

  public static convertDateFromCustomFormat(date: string, customFormat: string, mFormat?: string): string {
    if (!Utilities.isVoid(date)) {
      if (Utilities.isVoid(mFormat)) {
        mFormat = 'L LTS';
      }
      if (moment(date, customFormat).isValid()) {
        return moment(date, customFormat).format(mFormat);
      }
    }
    return '';
  }

  public static getStringFromDate(date: any, mFormat?: string): string {
    if (!Utilities.isVoid(date)) {
      const d = DateUtilities.getDate(date);
      if (Utilities.isVoid(mFormat)) {
        mFormat = 'L LTS';
      }
      const res = moment(d.setHours(0, 0, 0, 0)).format(mFormat);
      if (moment(res, mFormat).isValid()) {
        return res;
      }
    }
    return null;
  }

  public static getStringFromMoment(date: any, mFormat?: string): string {
    if (!Utilities.isVoid(date)) {
      if (Utilities.isVoid(mFormat)) {
        mFormat = 'L LTS';
      }
      const res = moment(date).format(mFormat);
      if (moment(res, mFormat).isValid()) {
        return res;
      }
    }
    return null;
  }

  public static setMomentLocale() {
    moment.locale('it');
    moment.updateLocale('it', {
      relativeTime: {
        future: '%s',
        s: function (number, withoutSuffix, key, isFuture) {
          return 'alcuni secondi';
        },
      }
    });
  }
}
