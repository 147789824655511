// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//   ng build --base-href /fe/  --deploy-url /fe/  --output-hashing=all

export const environment = {
  production: false,
  // baseUrl: 'http://37.159.185.54:83/ttauri/',
  baseUrl: 'https://www.personale-ttauri.it/app/',
  // baseUrl: 'https://test.globalmultivendor.it/TTauri/app/',
  // baseUrl: 'http://10.10.10.46/ttauri/app/',
  // apiGoogle: 'AIzaSyBmdSdtWkhe6NGzQ_m-jvHC4apiEdXYjP4',



  // baseUrl: 'https://test.globalmultivendor.it/TTauri/app/',
  apiGoogle: 'AIzaSyCHkxjfltejJqJ8Lh09HtibB62jwb3x3hM',
  loader: false,
};
