import {Component, OnInit} from '@angular/core';
import {DateUtilities} from './shared/utilities/date-utilities.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  constructor() {
    DateUtilities.setMomentLocale();
    window.console.log = () => { };
  }

  deviceCurrent: MediaDeviceInfo;

  ngOnInit(): void {
    console.log(this.deviceCurrent);
    this.test();
  }

  test() {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      console.log('enumerateDevices() not supported.');
      return;
    }
  }

}
