import {Injectable} from '@angular/core';
import {CanLoad, Route, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {User, UserInfo} from './core/model/user';
import {UserStoreService} from './core/services/user-store.service';
import {Utilities} from './shared/utilities/utilities';
import {LoginService} from './core/services/login.service';
import { Location } from '@angular/common';
import { th } from 'date-fns/locale';


@Injectable()
export class ModulesLoadingGuard implements CanLoad {
  user: any;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private userStore: UserStoreService,
    private location: Location,
  ) {

  }

  canLoad(route: Route): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.user = this.userStore.getUSer();
      const path = this.location.path();
      if (this.user) {
        if ((route.path === 'admin') && (this.user.userInfo.auth === 0 || this.user.userInfo.auth === 1)) {
          observer.next(true);
          observer.complete();
        } else if ((route.path === 'employee') && (this.user.userInfo.auth === 2 || this.user.userInfo.auth === 3)) {
          observer.next(true);
          observer.complete();
        } else if ((route.path === 'client')) {
          observer.next(false);
          observer.complete();
        }
      } else {
        if ((route.path === 'client')) {
          observer.next(true);
          observer.complete();
        } else {
          this.isAccessReference(route, path);
        }
      }
    });
  }

  protected isAccessReference(route: Route, path: string) {
    const token = Utilities.getAccessReference();
    if (!Utilities.isVoid(token)) {
      this.loginService.getUSerForModule(token)
        .subscribe((response: any) => {
          if (response.userInfo) {
            const user = User.newUSerByResponse(response);
            this.userStore.init(user);
            const company = {
              id: user.userInfo.companyId,
              name: user.userInfo.companyName
            };
            this.userStore.setCompany(company);

            this.router.navigateByUrl(path);
           /* if (user && (user.userInfo.auth == 0  || user.userInfo.auth == 1)) {
              this.router.navigateByUrl('admin');
            } else {
              this.router.navigateByUrl('employee');
            }*/
          } else {
            this.router.navigateByUrl('login');
          }
        });
    } else {
      this.router.navigateByUrl('login');
    }



  }
}
