import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {CustomMenuModel} from '../../model/custom-menu.model';
import {Role} from '../../model/user';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit, OnChanges {
  @Input() menu: CustomMenuModel[];
  @Input() user: any;
  role: any;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.user && changes.user.currentValue) {
      this.role = (this.user.userInfo.auth == 0 || this.user.userInfo.auth == 1) ? Role.ADMIN : Role.EMPLOYEE;
    }
  }

}
