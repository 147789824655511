import {CustomMenuModel} from '../../core/model/custom-menu.model';
import {AdminRoutesModel} from '../admin.routes.model';

export enum AdminMenuLabel {
  HOME = 'Home',
  CLIENT = 'Clienti',
  JOBS = 'Appalti',
  EMPLOYEE = 'Dipendenti',
  CALENDAR = 'Pianificazione',
  WORKS = 'Gestione Squadre',
  ABSENCES = 'Assenze',
  COMPANY = 'Gestione Azienda',
  QRMANAGEMENT = 'Gestione QR-Code'
}

export abstract class AdminPagesModel {
  public static adminMenu: CustomMenuModel[] = [
    {
      label: AdminMenuLabel.HOME,
      page: AdminRoutesModel.HOME,
      icon: 'dashboard'
    },
    {
      label: AdminMenuLabel.JOBS,
      page: AdminRoutesModel.JOBS,
      icon: 'gavel'
    },
    {
      label: AdminMenuLabel.CLIENT,
      page: AdminRoutesModel.CLIENTLIST,
      icon: 'view_list'
    },
    {
      label: AdminMenuLabel.ABSENCES,
      page: AdminRoutesModel.ABSENCES,
      icon: 'manage_accounts'
    },
    {
      label: AdminMenuLabel.EMPLOYEE,
      page: AdminRoutesModel.EMPLOYEE,
      icon: 'people'
    },
    {
      label: AdminMenuLabel.CALENDAR,
      page: AdminRoutesModel.CALENDAR,
      icon: 'calendar_today'
    },
    {
      label: AdminMenuLabel.WORKS,
      page: AdminRoutesModel.WORKS,
      icon: 'work'
    },
    {
      label: AdminMenuLabel.COMPANY,
      page: AdminRoutesModel.COMPANY,
      icon: 'business'
    },
    {
      label: AdminMenuLabel.QRMANAGEMENT,
      page: AdminRoutesModel.QRMANAGEMENT,
      icon: 'qr_code'
    }
  ];
}


