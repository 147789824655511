import {PipeTransform, Pipe} from '@angular/core';
import {DateUtilities} from '../utilities/date-utilities.model';
import {Utilities} from '../utilities/utilities';

@Pipe({
  name: 'Paride'
})
export class DateUtcPipe implements PipeTransform {
  transform(date: any, formats: string[]): string {

    const d =  DateUtilities.getDate(date);
    if (d.getHours() > 0 && formats.length === 0) {
      formats = ['LL LT'];
    } else {
      formats = ['LL'];
    }


    if (Utilities.isVoid(formats)) {
      return DateUtilities.convertDate(date);
    }

    if (typeof formats === 'string') {
      return DateUtilities.convertDate(date, formats);
    }

    let result = '';
    for (let i = 0; i < formats.length; i++) {
      result += DateUtilities.convertDate(date, formats[i]);
      if (i !== formats.length - 1) {
        result += ' ';
      }
    }

    return result;
  }
}

@Pipe({
  name: 'dateCustomFormat'
})
export class DateCustomFormatPipe implements PipeTransform {
  transform(date: any, customFormat: string, format: string): string {
    return DateUtilities.convertDateFromCustomFormat(date, customFormat, format);
  }
}
