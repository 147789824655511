import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginComponent} from './components/login/login.component';
import {InnerMessageComponent} from './components/inner-message/inner-message.component';
import {CoreComponent} from './components/core/core.component';
import {HeaderComponent} from './components/header/header.component';
import {MenuComponent} from './components/menu/menu.component';
import {AngularMaterialModule} from '../meterial/angular-material.module';
import {MatSliderModule} from '@angular/material/slider';
import {ReactiveFormsModule} from '@angular/forms';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {RootComponent} from './root/root.component';
import {HttpClientModule} from '@angular/common/http';
import {SharedModule} from '../shared/shared.module';
import {RouterModule} from '@angular/router';
import { ForgotPasswordComponent } from './components/login/forgot-password/forgot-password.component';
import { UserActionDialogComponent } from './components/header/user-action-dialog/user-action-dialog.component';
import { BottomSheetOverviewComponent } from './components/header/bottom-sheet-overview/bottom-sheet-overview.component';


@NgModule({
  declarations: [
    LoginComponent,
    InnerMessageComponent,
    CoreComponent,
    HeaderComponent,
    MenuComponent,
    RootComponent,
    ForgotPasswordComponent,
    UserActionDialogComponent,
    BottomSheetOverviewComponent
  ],
  exports: [
    CoreComponent,
    RootComponent
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    MatSliderModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    SharedModule,
    RouterModule,
  ],
  providers: [],
})
export class CoreModule {
}
