<div class="container-message">
  <div class="container-fluid p-0">
    <div *ngFor="let alert of alerts" class="{{cssClass(alert)}} p-2">
      <div>
        <div class="close closebtn">
          <div (click)="removeAlert(alert)" >
            <mat-icon aria-hidden="false" aria-label="Example close icon">close</mat-icon>
          </div>
        </div>
      </div>
      <h3 class="custom-title" [innerHTML]="alert.message"></h3>
    </div>
  </div>
</div>



