import {Injectable} from '@angular/core';
import {PollingBaseService} from '../../shared/models/pollong-base-service.model';
import {AlertService} from './alert.service';

@Injectable({
  providedIn: 'root'
})
export class AlertPollingService extends PollingBaseService {
  protected BASE_TIMER = 20000;

  constructor(private alertService: AlertService) {
    super();
  }

  settingPolling() {
    this.startPolling();
  }

  doAction() {
    const body = {
      filter: 'new'
    };
    return this.alertService.getLatestAlerts(body)
      .subscribe(resp => {
        this.pollingSubject.next(resp);
      });
  }
}
