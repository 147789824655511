import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LoginService} from '../../../services/login.service';

@Component({
  selector: 'app-user-action-dialog',
  templateUrl: './user-action-dialog.component.html',
  styleUrls: ['./user-action-dialog.component.css']
})
export class UserActionDialogComponent implements OnInit {

  changePasswordForm: FormGroup;
  changePasswordKO: boolean;
  hide = true;
  hide1 = true;
  hide2 = true;

  constructor(
    private loginService: LoginService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<UserActionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.changePasswordForm = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      copyNewPassword: ['', Validators.required],
    });
  }

  changePassword() {
    if (!this.changePasswordForm.valid) {
      this.changePasswordKO = true;
      return;
    }
    const passwords = this.changePasswordForm.getRawValue();
    if (passwords.newPassword !== passwords.copyNewPassword) {
      this.changePasswordKO = true;
    } else {
      const bodyChangePassword = {
        password: passwords.newPassword,
        oldPassword: passwords.oldPassword
      };
      this.loginService.changePassWord(bodyChangePassword)
        .subscribe((response: any) => {
          this.dialogRef.close();
        });
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
